// Webpack Imports
import 'bootstrap';
import owlCarousel from 'owl.carousel';
import 'jquery-lazy';
import matchHeight from 'jquery-match-height';
import AOS from 'aos';

window.owlCarousel = owlCarousel;
window.fancybox = require('fancybox')($);
window.matchHeight = matchHeight;

var kenEvents = Object;
kenEvents.current_width = function () {
	return jQuery(window).width();
};
kenEvents.current_height = function () {
	return Math.max(
		document.documentElement.clientHeight,
		window.innerHeight || 0
	);
};

kenEvents.scrollToId = function () {
	jQuery('a[href*="#"]:not([href="#"])').click(function () {
		if (
			location.pathname.replace(/^\//, "") ==
			this.pathname.replace(/^\//, "") &&
			location.hostname == this.hostname
		) {
			var target = jQuery(this.hash);
			target = target.length
				? target
				: jQuery("[name=" + this.hash.slice(1) + "]");
			if (target.length && target.hasClass('.dropdown-item') === false) {
				var locationTop = target.offset().top - 90;
				jQuery("html, body").animate(
					{
						scrollTop: locationTop,
					},
					500
				);
				return false;
			}
		}
	});
};

// var xxl = 1280;
var xxl = 1024;

jQuery(document).ready(function ($) {

	'use strict';
	kenEvents.scrollToId();
	// scroll animate
	$('.dropdown-scroll a').click(function (e) {
		e.preventDefault();
		var target = $($(this).attr('href'));
		if (target && target.offset()) {
			$('html, body').animate({ scrollTop: (target.offset().top - (2 * $('#navSite').outerHeight())) }, 1000);
		}
	});

	$('.link-internal a').click(function (e) {
		var link = $(this).attr('href').split('#');
		var target = $('#' + link[1]) || false;
		if (target && target.offset()) {
			$('html, body').animate({ scrollTop: (target.offset().top - ($('#navSite').outerHeight())) }, 1000);
			e.preventDefault();
		}
	});

	// Focus Search if Searchform is empty desktop
	$('#searchForm').on('submit', function (e) {
		var search = $('#query');
		if (search.val().length < 1) {
			e.preventDefault();
			search.trigger('focus');
		}
	});
	// Focus Search if Searchform mobile is empty 
	$('#searchFormM').on('submit', function (e) {
		var search = $('#queryM');
		if (search.val().length < 1) {
			e.preventDefault();
			search.trigger('focus');
		}
	})

	function rowCal() {

		$('.s2').each(function () {
			var self = $(this),
				row = self.find('.container-fluid>.row'),
				colFirst = row.children('div:first-child'),
				colLast = row.children('div:last-child'),
				bodyWidth = $('body').width(),
				spacer = (bodyWidth - $('.container:first-child').width() - 30) / 2;

			// banner right
			if (self.hasClass('banner-right')) {
				colFirst.css({
					// 'margin-left': -spacer + 'px',
					'padding-left': bodyWidth >= xxl ? spacer + 'px' : '15px',
				});
			} else { // banner left
				colLast.css({
					'padding-right': bodyWidth >= xxl ? spacer + 'px' : '15px',
				});
			}
		});
	}

	// owl Carousel slider
	if ($('.tpf-carousel').length) {

		$('.tpf-carousel').each(function (index, item) {
			var oc = $(this);
			var options = {
				items: oc.data('items') || 1,
				loop: oc.data('loop') === true ? true : false,
				margin: oc.data('margin') || 0,
				dots: oc.data('dots') === false ? false : true,
				nav: oc.data('nav') === true ? true : false,
				responsive: {
					580: {
						items: oc.data('items-580') || 1
					},
					992: {
						items: oc.data('items-992') || 1
					},
					1024: {
						items: oc.data('items-1024') || 1
					}
				},
			}
			oc.owlCarousel(options);
		});
	}

	// search icon
	$('#mSearchIcon').click(function (e) {
		e.preventDefault();
		$('body').toggleClass('search-open');
		if ($('body').hasClass('search-open')) {
			$('#sm').select();
		}
	});

	// dropdown-toggle click on mobile
	$('.icon-caret').click(function (e) {
		e.preventDefault();
		var self = $(this);
		setTimeout(function () {
			self.parents('.dropdown').toggleClass('open');
		}, 200);
	});

	// Temporary fix for client
	if ($('.card-link').length > 0) {
		$('.card-link').each(function () {
			$(this).attr('aria-expanded', true)
			var carId = $(this).attr('href');
			if ($(carId).length > 0) {
				$(carId).addClass('show');
			}
		})
	}
	if ($('.woosingle__description').length > 0) {
		setTimeout(function () {
			$('.woosingle__description p').each(function () {
				$(this).show();
			})
		}, 1200);

	}
	if ($('.woosingle__retailer').length > 0) {
		$('.woosingle__retailer.toggle').each(function () {
			$(this).show();
		})
	}
	if ($('.woosingle__retailers--more').length > 0) {
		$('.woosingle__retailers--more').text('Show less retailers');
	}
	if ($('.woosingle__description--button').length > 0) {
		$('.woosingle__description--button').text('Read less');
	}


	// navbar-toggler
	$('.navbar-toggler').click(function () {
		$('body').toggleClass('menu-open');
	});
	$('.nav-item.dropdown').dropdown('dispose');

	// cal row
	rowCal();

	// Lazy Loading 
	$('.lazy').lazy({
		effect: 'fadeIn',
		effectTime: 300
	});

	// jquery-match-height
	$('[data-mh]').matchHeight();

	// fancybox call
	$(".fancybox").fancybox({
		// animationEffect: 'fade',
	});

	//  aos animate
	AOS.init({
		duration: 1000,
		once: true,
	});

	// sticky menu
	$(window).scroll(function () {
		var height = $('.top-navbar').height() || 50;
		var navSite = $('#navSite');
		if ($(this).scrollTop() > height) {
			navSite.addClass('sticky');
		}
		else {
			navSite.removeClass('sticky');
		}
		if ($(this).scrollTop() >= height) {
			navSite.addClass('in-view');
		} else {
			navSite.removeClass('in-view');
		}
	});

	$(window).resize(function () {
		// recal column
		rowCal();

		// dropdown menu
		// if ($(window).width() < 992) {
		// $(".dropdown-toggle").attr('data-toggle', 'dropdown');
		// } else {

		$(".dropdown-toggle").removeAttr('data-toggle dropdown');
		$('.dropdown').removeClass('open');
		$('.dropdown-menu').removeClass('show');

		if ($(window).width() >= 992) {
			$('body').removeClass('menu-open search-open');
		}
	});
});